<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <h2 class="faledia-caption">Darstellungen vernetzen</h2>
      <v-card outlined>
        <v-card-title>Was sollen Schüler:innen lernen?</v-card-title>
        <v-card-text
          >Die Kinder können...
          <ul>
            <li>
              Darstellungsmitteln die intendierte(n) Aufgabe(n) zuordnen
              (Lernstoff)
            </li>
            <li>
              Darstellungsmittel zur Lösung einer Aufgabe nutzen (Lernhilfe)
            </li>
            <li>
              die Darstellung einer Aufgabe flexibel von einer Darstellung in
              eine andere übertragen
            </li>
            <li>
              die Erklärung einer anderen Person zu einem Darstellungswechsel
              wiedergeben
            </li>
            <li>ausgeführte Darstellungswechsel erklären</li>
            <li>
              beim Darstellungswechsel alle Elemente einer Aufgabe
              berücksichtigen und sich nicht nur auf einen Einzelaspekt
              beschränken
            </li>
          </ul></v-card-text
        >
      </v-card>

      <br />

      <h3 class="faledia-caption">
        Was sollten Sie zum Darstellungswechsel wissen
      </h3>
      <div class="content">
        <p>
          Neben dem Wissen um die Grundvorstellungen sollten Lehrkräfte aber
          auch Wissen zum Wechsel zwischen den Darstellungsformen haben. Die
          Darstellungsformen lassen sich dabei in vier Kategorien unterscheiden,
          wie die folgende Abbildung zeigt:
        </p>
      </div>

      <div class="center">
        <AppExpandableImage
          contain
          :src="require('@/assets/ove/ove_darstellungsformen.png')"
          parentId="ov"
          elementId="ove_darstellungsformen.png"
        >
        </AppExpandableImage>
        <p style="text-align: right">
          entnommen aus
          <i
            ><a href="//primakom.dzlm.de/node/187"
              >primakom.dzlm.de/node/187</a
            ></i
          >
        </p>
      </div>

      <p>
        Das heißt beispielsweise, dass Kinder zu den unterschiedlichen
        Operationen Darstellungen miteinander vernetzen können. So zeigt das
        untere Beispiel, die Vernetzung der vier Darstellungsformen zu der
        Aufgabe 4 + 3, die alle denselben Kontext abbilden.
      </p>

      <div class="center">
        <AppExpandableImage
          contain
          :src="require('@/assets/ove/ove_4plus3.png')"
          parentId="ov"
          elementId="ove_4plus3.png"
        >
        </AppExpandableImage>
      </div>

      <p>
        Für die unterschiedlichen Darstellungen gilt: Sie sind einerseits
        Lernhilfe, da sie es den Schülerinnen und Schülern erleichtern
        allgemeine, universellere mathematische Begriffe zu erwerben. Sie sind
        andererseits aber stets auch Lernstoff. Denn es gibt keine simple
        Eins-zu-eins-Zuordnung zwischen Darstellungsmittel und mathematischem
        Begriff, keine Einbahnstraße vom ‚Konkreten‘ zum ‚Abstrakten‘.
      </p>
      <p>
        Es hat sich gezeigt, dass Kinder beim Darstellungswechsel auf
        unterschiedliche Kriterien achten (Kuhnke, 2012). Im Folgenden wird dies
        anhand der Beispielaufgabe, passende bildliche Darstellungen zu der
        vorgegebenen Aufgabe 3 · 4 auszuwählen, verdeutlicht. Das scheint auf
        den ersten Blick noch recht simpel, doch die Komplexität nimmt zu sobald
        der Darstellungswechsel nicht mehr so gut erprobt ist.
      </p>

      <video
        class="lbst-border"
        preload="auto"
        controls
        width="60%"
        style="margin-left: 20%"
        @play="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @fullscreenchange="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @webkitfullscreenchange="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @mozfullscreenchange="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @msfullscreenchange="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @ended="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @pause="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @seeking="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @enterpictureinpicture="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
        @leavepictureinpicture="videoPlayerEvent($event, 'ODWH 1_neu.mp4')"
      >
        <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
        <source src="@/assets/ovi/ODWH 1_neu.mp4" type="video/mp4" />
      </video>
      <div>
        <p>
          In der Multiplikation zweier natürlicher Zahlen fällt es häufig
          leichter eine Einteilung vorzunehmen. Schließlich haben die meisten
          Erwachsenen schon eine Vorstellung zur Multiplikation aufgebaut. Den
          Kindern fällt dies häufig noch schwerer, so werden beispielsweise nur
          Teile der Aufgabe – wie Multiplikator und Multiplikand oder das
          Ergebnis – fokussiert.
        </p>
        <p>
          Die nächste Aktivität dient vor allem dazu, sich in die Kinder
          hineinversetzen zu können. Der Vorstellungsaufbau – der mit der
          Fähigkeit zum Darstellungswechsel einhergeht – für eine neue oder
          ungewohnte, wenig genutzte und geschulte Grundvorstellung erscheint
          dadurch weniger trivial und ist vermutlich zunächst komplexer und
          etwas anspruchsvoller nachzuvollziehen.
        </p>
      </div>

      <div style="background-color: white">
        <LernbausteinGruppen
          :id="LBSTGruppen[0].id"
          :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
          :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
          :showGroupNames="LBSTGruppen[0].showGroupNames"
          :workOrder="LBSTGruppen[0].workOrder"
          :possibleCategories="LBSTGruppen[0].possibleCategories"
          :elements="LBSTGruppen[0].elements"
          :responses="LBSTGruppen[0].responses"
        >
          <template v-slot:alternative-content>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
              @fullscreenchange="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'ODWH 2_neu.mp4')
              "
              @mozfullscreenchange="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
              @msfullscreenchange="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
              @ended="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
              @pause="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
              @seeking="videoPlayerEvent($event, 'ODWH 2_neu.mp4')"
              @enterpictureinpicture="
                videoPlayerEvent($event, 'ODWH 2_neu.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'ODWH 2_neu.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source src="@/assets/ovi/ODWH 2_neu.mp4" type="video/mp4" />
            </video>
          </template>
        </LernbausteinGruppen>
      </div>

      <AppButtonCollapse>
        <div style="background-color: white">
          <LernbausteinGruppen
            :id="LBSTGruppen[1].id"
            :checkAfterHowManyItems="LBSTGruppen[1].checkAfterHowManyItems"
            :checkAfterExactItemCount="LBSTGruppen[1].checkAfterExactItemCount"
            :showGroupNames="LBSTGruppen[1].showGroupNames"
            :workOrder="LBSTGruppen[1].workOrder"
            :possibleCategories="LBSTGruppen[1].possibleCategories"
            :elements="LBSTGruppen[1].elements"
            :responses="LBSTGruppen[1].responses"
          >
            <template v-slot:alternative-content>
              <video
                preload="auto"
                controls
                width="60%"
                style="margin-left: 20%"
                @play="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
                @fullscreenchange="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
                @webkitfullscreenchange="
                  videoPlayerEvent($event, 'ODWH 3_neu.mp4')
                "
                @mozfullscreenchange="
                  videoPlayerEvent($event, 'ODWH 3_neu.mp4')
                "
                @msfullscreenchange="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
                @ended="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
                @pause="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
                @seeking="videoPlayerEvent($event, 'ODWH 3_neu.mp4')"
                @enterpictureinpicture="
                  videoPlayerEvent($event, 'ODWH 3_neu.mp4')
                "
                @leavepictureinpicture="
                  videoPlayerEvent($event, 'ODWH 3_neu.mp4')
                "
              >
                <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
                <source src="@/assets/ovi/ODWH 3_neu.mp4" type="video/mp4" />
              </video>
            </template>
          </LernbausteinGruppen>
        </div>
      </AppButtonCollapse>
      <p>
        Die Vernetzung der Darstellungen ist für ungewohntere Aufgaben
        schwieriger. Für die Kinder, die gerade erst den Wechsel zwischen den
        Darstellungen kennenlernen, ist dies ebenfalls nicht trivial. Um ein
        umfangreiches Operationsverständnis aufzubauen, sind Darstellungswechsel
        und somit eine Vernetzung aller Darstellungsformen aber hilfreich.<br />
        Erstaunlich und nicht zu vernachlässigen ist die Tatsache, dass viele
        Kinder bereits einzelne Malaufgaben kennen, bevor die Multiplikation im
        Unterricht eingeführt wurde. Daher ist es nicht verwunderlich, dass
        einige Kinder beim Darstellungswechsel von symbolischen und bildlichen
        Darstellungen auf das <em>Gesamtergebnis</em> achten. Dementsprechend
        ‚passen‘ Darstellungen für sie zusammen, wenn beide dasselbe Ergebnis
        haben. Beispielsweise werden alle Darstellungen mit 12 Elementen -
        unabhängig von deren Anordnung - der Aufgabe 3 · 4 zugeordnet. Einige
        Kinder fokussieren besonders auch <em>einzelne Elemente</em> die in
        beiden Darstellungen vorkommen müssen. Sie nehmen zum Beispiel die 4 in
        den Blick und wählen weitere Darstellungen aus, bei welchen mehrmals 4
        zu sehen sind. Andere Kinder fokussieren sich auf die Relation der
        Elemente. Darstellungen ‚passen‘ immer dann zusammen, wenn in beiden
        dieselbe Relation zu finden ist. Für das Beispiel 3 · 4 heißt das: Alle
        Darstellungen in welchen genau drei Vierer zu finden sind, auch
        unabhängig von der Anordnung innerhalb der Darstellung, werden als
        passend klassifiziert. <br />
        Gerade bei schwächeren Kindern zeigt sich, dass Handlungen am konkreten
        Material weniger eine Hürde darstellen. Die Umstellung aber vom
        Konkreten weg, hin zum gedanklichen Handeln, scheint den Kindern schwer
        zu fallen (vgl. vom Hofe 1995)
      </p>
      <br />
      <AppLiteraturOV />
    </v-card>
    <AppBottomNavOV
      back="/operationsverstaendnis/hintergrundwissen/grundvorstellungen"
      next="/operationsverstaendnis/hintergrundwissen/aufgabenbeziehungen"
    />
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
import AppLiteraturOV from "@/common/AppLiteraturOV";
import AppButtonCollapse from "@/common/AppButtonCollapse";
import AppBottomNavOV from "@/common/AppBottomNavOV";
import AppExpandableImage from "@/common/AppExpandableImage";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_ODWH_2_ODWH2 from "@/components/faledia/seiten/ove/beispiele/ODWH_2_ODWH2_V2";
import LernbausteinGruppen_ODWH_2_ODWH3 from "@/components/faledia/seiten/ove/beispiele/ODWH_2_ODWH3_V2";
export default {
  components: {
    AppButtonCollapse,
    AppBottomNavOV,
    LernbausteinGruppen,
    AppLiteraturOV,
    AppExpandableImage,
  },
  data: () => ({
    LBSTGruppen: [
      LernbausteinGruppen_ODWH_2_ODWH2,
      LernbausteinGruppen_ODWH_2_ODWH3,
    ],
  }),
  methods: {
    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>

<style scoped>
#app .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  max-width: 50%;
}
</style>
