<template>
  <AppBottomNav :items="items" :back="back" :next="next" />
</template>

<script>
import AppBottomNav from "./AppBottomNav";
export default {
  props: {
    back: String,
    next: String,
  },
  components: {
    AppBottomNav,
  },
  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Operationsverständnis",
          to: "/operationsverstaendnis",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Hintergrundwissen",
          to: "/operationsverstaendnis/hintergrundwissen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Grundvorstellungen besitzen",
          to: "/operationsverstaendnis/hintergrundwissen/grundvorstellungen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Darstellungen vernetzen",
          to: "/operationsverstaendnis/hintergrundwissen/darstellungen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Aufgabenbeziehungen nutzen",
          to: "/operationsverstaendnis/hintergrundwissen/aufgabenbeziehungen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Übergang Diagnose",
          to: "/operationsverstaendnis/uebergang",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Diagnose & Förderung",
          to: "/operationsverstaendnis/diagnose-und-foerderung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kompetenzliste",
          to: "/operationsverstaendnis/check",
        },
      ],
    };
  },
};
</script>

<style></style>
