<style scoped>
div {
  background: rgba(0, 0, 0, 0) !important;
}
</style>
<template>
  <AppButtonCollapse
    icon="mdi-book-open-page-variant-outline"
    messageHidden="Literatur anzeigen"
    messageNotHidden="Literatur verstecken"
    elevation="0"
  >
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Gaidoschik, M. (2007).
                <i
                  >Rechenschwäche vorbeugen – Erstes Schuljahr: Vom Zählen zum
                  Rechnen.</i
                >
                Wien: ÖBV HPT.
              </td>
            </tr>
            <tr>
              <td>
                Häsel-Weide, U., & Nührenbörger, M. (2012).
                <i>Fördern im Mathematikunterricht.</i> In H. Bartnitzky, U.
                Hecker & M. Lassek (Hrsg.),
                <i
                  >Individuell fördern – Kompetenzen stärken – in der
                  Eingangsstufe.</i
                >
                Frankfurt am Main: Grundschulverband.
              </td>
            </tr>
            <tr>
              <td>
                Kuhnke, K. (2012).
                <i
                  >Vorgehensweisen von Grundschulkindern beim
                  Darstellungswechsel – Eine Untersuchung am Beispiel der
                  Multiplikation im 2. Schuljahr.</i
                >
                Wiesbaden: Springer.
              </td>
            </tr>
            <tr>
              <td>
                Schipper, W. (2005).
                <i
                  >Lernschwierigkeiten erkennen - verständnisvolles Lernen
                  fördern.</i
                >
                http://www.sinus-angrundschulen.de/fileadmin/uploads/Material_aus_STG/Mathe-Module/M4.pdf
              </td>
            </tr>
            <tr>
              <td>
                Selter, C., & Zannetin, E. (2018).
                <i
                  >Mathematik unterrichten in der Grundschule. Inhalte –
                  Leitideen – Beispiele.</i
                >
                Seelze: Kallmeyer.
              </td>
            </tr>
            <tr>
              <td>
                vom Hofe, R. (1995).
                <i>Grundvorstellungen mathematischer Inhalte.</i> Heidelberg:
                Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Wartha, S., & Schulz, A. (2011).
                <i
                  >Aufbau von Grundvorstellungen (nicht nur) bei besonderen
                  Schwierigkeiten im Rechnen.</i
                >
                Kiel: IPN.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </AppButtonCollapse>
</template>

<script>
import AppButtonCollapse from "@/common/AppButtonCollapse";

export default {
  name: "AppLiteraturOV",
  components: {
    AppButtonCollapse,
  },
};
</script>
